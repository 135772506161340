<template>
  <div class="manageBox">
    <div class="content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="流程名称:" prop="name">
          <span>{{ruleForm.name}}</span>
        </el-form-item>
        <el-form-item label="服务对象类别:" prop="serviceObjectIds">
          <span>{{ruleForm.serviceObjectName}}</span>
        </el-form-item>
        <el-form-item label="审批流程" prop="process">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in ruleForm.process" :key="index">
              <div v-if="item.isMute === '1'" slot="dot" class="dot">
                <img src="../../../assets/imgs/processCheck.png" width="20" height="20" alt="">
              </div>
              <div v-else slot="dot" class="dot1">
                <img src="../../../assets/imgs/processClose.png" width="20" height="20" alt="">
              </div>
              <div class="process-box">
                <div :class="index === 0 ? 'top-title-bcyellow' : item.isMute === '1' ? 'top-title-bcgreen' : 'top-inactive'">
                  <div>{{index === 0 ? '提交人' : '审批人'}}</div>
                  <!--<img v-if="item.isMute === '1' && item.isShowClose" @click="delItem(index)" src="../../../assets/imgs/proClose.png" width="16px" height="16px" alt="">
                  <span v-if="item.isMute === '0' && item.isShowClose" @click="delItem(index)" alt=""><i class="el-icon-error"></i></span>-->
                </div>
                <div class="top-content">
                  <span>{{item.name || ( index === 0 ? '请选择提交人': '请选择审批人')}}</span>
                  <span> > </span>
                </div>
              </div>
            </el-timeline-item>
<!--            <img src="../../../assets/imgs/puls.png" style="cursor: pointer" width="24px" height="24px" class="plus" alt="">-->
          </el-timeline>
        </el-form-item>
      </el-form>
      <!--横线-->
      <div style="border-bottom: 1px solid #ccc;padding-top: 30px"></div>
      <!--表格-->
      <div class="table-box">
        <div class="topInfo">
          <span>审批附件类型</span>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          :row-style="{ height: '55px' }"
          style="width: 100%"
          :header-cell-style="headerCellStyle"
          class="content-itme"
        >
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column prop="name" align="center" label="附件名称"/>
          <el-table-column prop="attachmentDescribe" align="center" label="附件描述"/>
          <el-table-column prop="phone" align="center" label="是否必填">
            <template slot-scope="scope">
              <span>{{['是', '否'][scope.row.isRequired]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" class="operation" @click="toView(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="btns">
          <el-button  type="primary" plain @click="$router.go(-1)">取 消</el-button>
<!--          <el-button  type="primary" @click="submitForm('ruleForm')">保 存</el-button>-->
        </div>
      </div>
    </div>
  <fileView v-if="fileViewVisible" :fileViewVisible.sync="fileViewVisible" :rowItem="rowItem"></fileView>
  </div>
</template>

<script>
  import fileView from "./components/fileView";
  import {selectEnclosure, selectById } from "@/api/homeCasedCareServices"
  export default {
    name: "addOrEditSubsidyApprovalProcess",
    components: {
      fileView
    },
    data() {
      return {
        rowItem: {},
        configOpt: [],
        loading: false,
        tableData: [],
        fileViewVisible: false,
        ruleForm: {
          process: [
            {
              id: "2",
              name: '街道办事处',
              isMute: "1",
              type: 0,
              isShowClose: false
            },
            {
              id: "1",
              name: '社区居委会',
              isMute: "0",
              type: 0,
              isShowClose: false
            },
            {
              id: "3",
              name: '街道办事处',
              isMute: "1",
              type: 0,
              isShowClose: false
            },
          ],
          name: '',
          serviceObjectIds: [], //类别Id
          serviceObjectName: '' //类别名字
        },
        rules: {
          name: [
            { required: true, message: '请输入流程名称', trigger: 'blur' },
            { max: 20, message: '流程名称最长20个字符', trigger: 'blur' }
          ],
          serviceObjectIds: [
            { required: true, message: '请选择服务对象类别', trigger: 'change' },
          ],
          process: [
            { required: true, message: '请选择完善审批流程', trigger: 'change' },
          ]
        },
        rowId: '',
        isNeedValid: false
      }
    },
    watch: {
      'ruleForm.process': {
        deep: true,
        handler(val) {
          let isNeedValid = val.slice(1).every(item => {
            return item.isMute == 0
          })
          this.isNeedValid = isNeedValid
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      to.meta.title =  '查看'
      next()
    },
    mounted() {
      this.rowId = this.$route.query.id
      this.selectEnclosureData() //获取附件列表
      this.selectByIdData()
    },
    methods: {

      /*获取详情数据*/
      async selectByIdData() {
        const res = await selectById({id:this.rowId})
        let { list, name , process, serviceObjectName, serviceObjectIds } = res.data
        this.ruleForm.process = JSON.parse(process)
        this.tableData = list
        this.ruleForm.name = name
        this.ruleForm.serviceObjectIds = serviceObjectIds.split(',')
        this.ruleForm.serviceObjectName = serviceObjectName
      },
      /*获取附件列表*/
      async selectEnclosureData() {
        const res = await selectEnclosure()
        this.tableData = res.data
      },
      /*上传或者新增附件*/
      toView(row) {
        this.rowItem = row
        this.fileViewVisible = true
      },
      /*获取新增审批人id*/
      getOtherIds() {
        let arrids = []
        let arr = JSON.parse(JSON.stringify(this.ruleForm.process)).slice(3)
        arr.forEach(item => {
          if(item && item.id) arrids.push(item.id)
        })
        return arrids
      },
      /*新增审批人回传数据*/
      postOtherAudit(data) {
        this.otherAuditPersonItem.id = data.id
        this.otherAuditPersonItem.name = data.name
        this.otherAuditPersonItem.isMute = '1'
      },
      /*选择提交人回传的数据*/
      postSelectData(data) {
        this.submitPersonItem.id = data.id
        this.submitPersonItem.name = data.name
      },
    }
  }
</script>

<style lang="scss" scoped>
  .stop {
    color: #f57473;
  }
  .manageBox {
    background: #fff;
    min-height: calc(100vh - 130px);
    .content {
      padding: 20px;
      .table-box {
        width: 50%;
        .topInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 30px 0;
        }
        .btns {
          display: flex;
          justify-content: center;
          margin-top: 30px;
        }
        .btns>button:first-child {
          margin-right: 40px;
        }
      }
      /deep/ .el-timeline-item__tail {
        left: 9px;
        border-left: 1px solid #3FCF89;
      }
/*      /deep/ .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
        display: block;
      }*/
      .plus {
        position: absolute;
        left: 39px;
      }
      .dot {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        /*border: 1px solid #3FCF89;*/
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        color: #3FCF89;
        font-size: 14px;
      }
      .dot1 {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        /*border: 1px solid #D0D0D0;*/
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        color: #3FCF89;
        font-size: 14px;
      }
      .process-box {
        width: 200px;
        border-radius: 4px;
        border: 1px solid #EEEEEE;
        margin-left: 30px;
        .top-title-bcgreen {
          background-color: #3FCF89;
          height: 25px;
          display: flex;
          align-items: center;
          position: relative;
          div {
            flex: 1;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
          }
          img {
            cursor: pointer;
            position: absolute;
            right: 10px;
          }
        }
        .top-title-bcyellow {
          height: 25px;
          background-color: #F7C261;
          line-height: 25px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
        }
        .top-inactive {
          background-color: #cccccc;
          height: 25px;
          display: flex;
          align-items: center;
          position: relative;
          div {
            flex: 1;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
          }
          span {
            display: inline-block;
            cursor: pointer;
            position: absolute;
            right: 10px;
            i {
              font-size: 16px;
              color: #ffffff;
            }
          }
        }
        .top-content {
          height: 50px;
          color: #333333;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
</style>
